import React, { Children, ReactNode } from 'react';

type EachProps<T> = {
    items: T[];
    Render: (item: T, index: number) => ReactNode;
};

export const Each = <T extends unknown>({ Render, items }: EachProps<T>): JSX.Element => (
    <>{Children.toArray(items.map((item, index) => Render(item, index)))}</>
);
